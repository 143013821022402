export const RoutePaths = {
  landing: '/',
  settings: '/settings',
  //PLOP_TEMPLATE: ROUTE PATHS
  manageshiporderaccountinfo: '/ship-orders/account-info/edit',
  itemedit: '/inventory/items/edit/',
  markordershipped: '/ship-orders/ship',
  reviewreturnedpackage: '/ship-orders/review-returned-package',
  processorder: '/ship-orders/process',
  editorder: '/ship-orders/edit/',
  login: '/login',
  clientdetails: '/client-details',
  employeedetails: '/employee-details',
  createemployee: '/create-employee',
  createwarehouse: '/settings/create-warehouse',

  createexhibit: '/exhibit/create-exhibit',
  dashboard: '/dashboard',
  people: '/people',
  globaldata: '/global-data',
  inventory: '/inventory',
  projects: '/projects',
  //SUB-ROUTES
  //Global Data
  vendors: '/global-data/vendors',
  events: '/global-data/events',
  venue: '/global-data/venue',
  /* ship orders */
  shiporders: '/ship-orders',
  createshiporder: '/ship-orders/create',
  shiporderssummary: '/ship-orders/summary',
  //People
  employees: '/people/employees',
  clients: '/people/clients',
  createclient: '/create-client',
  //Inventory
  createItemCategories: '/inventory/item-categories/create-category',
  itemCategories: '/inventory/item-categories',
  itemlist: '/inventory/add-items',
  itemAdjustStock: '/inventory/adjust-stock',
  items: '/inventory/items',
  itemdetail: '/item',
  itemskus: '/inventory/item-skus',
  itemCreate: '/inventory/create-item/rental-item',
  //Projects
  exhibit: '/exhibits',
  productDisplay: '/projects/product-display',
} as const;

export const AuthRoutePaths = Object.values(RoutePaths).filter(
  (path) => path === RoutePaths.login,
) as string[];

export const MainRoutePaths = Object.values(RoutePaths)
  .filter((path) => path !== RoutePaths.landing)
  .filter((path) => path !== RoutePaths.login) as string[];
